<script>
import ModelCard from "./ModelCard";
export default {
  props: {
    user: Object,
    isModal: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ModelCard,
  },
  data: function() {
    return {
      isOpen: false,
    };
  },
  computed: {
    state() {
      let { isActive, isOnline } = this.user;
      return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
    },
    myModels() {
      let modelsMonitor = this.$store.getters["connectedUsers/getModelsWithMonitor"];
      return modelsMonitor.filter((model) => model.monitorIdTemp === this.user._id);
    },
    modelToAssign: function() {
      let models = this.$store.getters["connectedUsers/getModels"] || [];
      let modelEmail = this.$store.state.user.usersToAssign.modelEmail;
      return models.find((model) => model.email === modelEmail);
    },
  },
  methods: {
    sendEmail(monitorEmail) {
      this.$store.state.user.usersToAssign.monitorEmail = monitorEmail;
    },
  },
};
</script>

<template>
  <div class="monitorCardAssign">
    <div class="monitorCardAssign__wrapperr">
      <div
        :class="['monitorCardAssign__infoMonitor', !isModal && myModels.length && 'monitorCardAssign__infoMonitor--list']"
        @click="isOpen = !isOpen"
      >
        <div class="monitorCardAssign__avatarContainer" :stateAvatar="state">
          <Avatar class="monitorCardAssign__avatar" :user="user" />
          <span class="monitorCardAssign__statusCircle" :stateAvatar="state"></span>
        </div>
        <div :class="['monitorCardAssign__userData', isModal && 'monitorCardAssign__userData--modal']">
          <span class="monitorCardAssign__name">{{ user.user }}</span>
          <div class="monitorCardAssign__info">
            <span>{{ user.city }}</span>
            <span v-if="user.office">{{ " - " + user.office }}</span>
          </div>
        </div>
        <div class="monitorCardAssign__buttonContainer">
          <button class="monitorCardAssign__button" v-if="isModal && canAccessToModel(user, modelToAssign)" @click="sendEmail(user.email)">
            +
          </button>
          <span class="monitorCardAssign__amount" v-else-if="!isModal && myModels.length">{{ myModels.length }}</span>
        </div>
      </div>
      <div class="monitorCardAssign__infoModels fade-in" v-if="!isModal && myModels.length && isOpen">
        <ModelCard class="monitorCardAssign__card " :user="model" :isListMonitor="true" v-for="(model, idx) in myModels" :key="model._id + idx" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.monitorCardAssign {
  &__wrapperr {
    padding: 5px 15px;
    background: #f3f3f3;
    border-radius: 27px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      background: rgba(242, 189, 189, 0.15);
      transition: 0.3s ease-in-out;
    }
  }
  &__infoMonitor {
    @include Flex(row, space-between);
    &--list {
      cursor: pointer;
    }
  }
  &__avatarContainer {
    position: relative;
    border: 2px solid $alto;
    border-radius: 50%;
    &[stateAvatar="online"] {
      border-color: $la_palma;
    }
    &[stateAvatar="away"] {
      border-color: $tree_Poppy;
    }
  }
  &__avatar {
    width: 37px;
    height: 37px;
    border: 1px solid $white;
  }
  &__statusCircle {
    position: absolute;
    width: 10px;
    height: 10px;
    right: 1px;
    bottom: 1px;
    border: 1px solid $white;
    border-radius: 50%;
    background-color: $alto;
    &[stateAvatar="online"] {
      background-color: $la_palma;
    }
    &[stateAvatar="away"] {
      background-color: $tree_Poppy;
    }
  }
  &__avatarContainer,
  &__buttonContainer {
    width: fit-content;
  }
  &__userData {
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: stretch;
    max-width: 142px;
    margin: 0 $mpadding;
    text-overflow: ellipsis;
    overflow: auto;
  }
  &__name {
    font-size: 1em;
    color: black;
  }
  &__info {
    font-size: 0.9em;
    color: $chicago;
  }
  &__buttonContainer {
    display: flex;
    width: 33px;
  }
  &__button {
    width: 33px;
    height: 33px;
    font-size: 33px;
    line-height: 4px;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 50%;
  }
  &__amount {
    text-align: center;
    padding: 0 $mpadding/2;
    width: 30px;
    font-size: 1.3em;
    background-color: $primary-color;
    color: white;
    border-radius: 5px;
  }
  &__card {
    margin-top: $mpadding/2;
  }
  @media screen and (min-width: $tablet-width) {
    &__userData {
      max-width: 100%;
      &--modal {
        max-width: 142px;
        text-overflow: ellipsis;
        overflow: auto;
      }
    }
  }
}
</style>
